/* HirePage.css */

:root {
  --primary-color: #000000;
  --secondary-color: #666666;
  --success-color: #000000;
  --background-color: #ffffff;
  --text-color: #000000;
  --border-color: #000000;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.logo {
  font-size: 28px;
  font-weight: bold;
  color: var(--primary-color);
}

nav a {
  margin-left: 20px;
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
}

nav a:hover {
  color: var(--primary-color);
}

.demo-btn, .sign-in-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.demo-btn {
  background-color: var(--background-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.demo-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.sign-in-btn {
  background-color: var(--primary-color);
  color: white;
}

.sign-in-btn:hover {
  background-color: #333333;
}

.hero {
  text-align: center;
  padding: 60px 0;
}

h1 {
  font-size: 48px;
  margin-bottom: 20px;
  color: var(--text-color);
}

.highlight {
  color: var(--primary-color);
}

.subheading {
  font-size: 18px;
  color: var(--secondary-color);
  max-width: 600px;
  margin: 0 auto 40px;
}

.demo-area {
  background-color: white;
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 25px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: var(--text-color);
}

.input-group input,
.input-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-group input:focus,
.input-group textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.25);
  outline: none;
}

.call-btn {
  background-color: var(--success-color);
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.call-btn:hover {
  background-color: #333333;
}

.call-status {
  margin-top: 20px;
  font-weight: 600;
  color: var(--primary-color);
}

.test-drive-text {
  margin-top: 40px;
  font-weight: 600;
  color: var(--text-color);
}

.role-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.role-btn {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  padding: 10px 20px;
  margin: 5px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.role-btn:hover {
  background-color: var(--primary-color);
  color: white;
}

.role-btn.active {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.job-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.job-card h3 {
  margin-bottom: 10px;
  color: var(--text-color);
}

.job-card p {
  color: var(--secondary-color);
}

.try-aivira-btn {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.try-aivira-btn:hover {
  background-color: #333333;
}

.features {
  padding: 60px 0;
  background-color: white;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.feature {
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature h3 {
  margin-bottom: 15px;
  color: var(--primary-color);
}

.call-options {
  margin-top: 60px;
  text-align: center;
}

.call-types {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.call-type {
  flex: 1;
  max-width: 300px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.call-type h4 {
  margin-bottom: 15px;
  color: var(--primary-color);
}

.demo-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.demo-modal h2 {
  margin-bottom: 20px;
  color: var(--primary-color);
}

.demo-modal button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.demo-modal button:hover {
  background-color: #333333;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  h1 {
    font-size: 36px;
  }

  .call-types {
    flex-direction: column;
    align-items: center;
  }

  .call-type {
    width: 100%;
    max-width: none;
  }
}